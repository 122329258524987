<template>
  <b-card>
    <h4 class="mb-0">
      Edit Siswa
    </h4>
    <validation-observer ref="validUpdate">
      <b-form
        class="mt-1"
        @submit.prevent="validationForm"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Name Lengkap"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name Lengkap"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataStudent.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Hendi Saputra"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NISN"
              label-for="nisn"
            >
              <validation-provider
                #default="{ errors }"
                name="NISN"
                rules="required"
              >
                <b-form-input
                  id="nisn"
                  v-model="dataStudent.nisn"
                  :state="errors.length > 0 ? false : null"
                  placeholder="9732398239XXX"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tempat Lahir"
              label-for="birth_place"
            >
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  id="birth_place"
                  v-model="dataStudent.birth_place"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Yogyakarta"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tanggal Lahir"
              label-for="birth_date"
            >
              <validation-provider
                #default="{ errors }"
                name="Tanggal Lahir"
                rules="required"
              >
                <b-form-input
                  id="birth_date"
                  v-model="dataStudent.birth_date"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Lahir"
                  type="date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Jenis Kelamin"
              label-for="gender"
            >
              <validation-provider
                #default="{ errors }"
                name="Jenis Kelamin"
                rules="required"
              >
                <v-select
                  id="gender"
                  v-model="dataStudent.gender"
                  :reduce="(gender) => gender.value"
                  :options="dataGender"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                  placeholder="Pilih Jenis Kelamin"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Agama"
              label-for="religion"
            >
              <validation-provider
                #default="{ errors }"
                name="Agama"
                rules="required"
              >
                <v-select
                  id="religion"
                  v-model="dataStudent.religion"
                  :reduce="(religion) => religion.value"
                  :options="dataReligion"
                  label="text"
                  placeholder="Pilih Agama"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Kewarganegaraan"
              label-for="nationality"
            >
              <validation-provider
                #default="{ errors }"
                name="Kewarganegaraan"
                rules="required"
              >
                <v-select
                  id="nationality"
                  v-model="dataStudent.nationality"
                  :reduce="(nationality) => nationality.name"
                  :options="dataCountries"
                  label="name"
                  placeholder="Pilih Kewarganegaraan"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Alamat"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="Alamat"
                rules="required"
              >
                <b-form-input
                  id="address"
                  v-model="dataStudent.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Jl. Kaliurang Km.07 B12"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Anak ke"
              label-for="child_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Anak ke"
                rules="required"
              >
                <b-form-input
                  id="child_number"
                  v-model="dataStudent.child_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Jumlah Saudara Kandung"
              label-for="number_of_sibling"
            >
              <validation-provider
                #default="{ errors }"
                name="Jumlah Saudara Kandung"
                rules="required"
              >
                <b-form-input
                  id="number_of_sibling"
                  v-model="dataStudent.number_of_sibling"
                  :state="errors.length > 0 ? false : null"
                  placeholder="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Golongan Darah"
              label-for="blood_type"
            >
              <validation-provider
                #default="{ errors }"
                name="Golongan Darah"
                rules="required"
              >
                <b-form-input
                  id="blood_type"
                  v-model="dataStudent.blood_type"
                  :state="errors.length > 0 ? false : null"
                  placeholder="O"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group label="Provinsi" label-for="province">
              <validation-provider
                #default="{ errors }"
                name="Provinsi"
                rules="required"
              >
                <v-select
                  :reduce="(province) => province.name"
                  id="province"
                  v-model="locations.province"
                  :options="dataProvinces"
                  label="name"
                  placeholder="Pilih Provinsi"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group label="Kabupaten/Kota" label-for="city">
              <validation-provider
                #default="{ errors }"
                name="Kabupaten/Kota"
                rules="required"
              >
                <v-select
                  :reduce="(city) => city.name"
                  id="city"
                  v-model="locations.city"
                  :options="filterCities"
                  label="name"
                  placeholder="Pilih Kabupaten/Kota"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="() => $router.go(-1)"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      nisn: '',
      name: '',
      birth_place: '',
      birth_date: '',
      gender: '',
      religion: '',
      nationality: '',
      child_number: '',
      number_of_sibling: '',
      relationship_in_family: '',
      every_day_language: '',
      address: '',
      distance: '',
      child_number_institution: '',
      reading_ability_quran: '',
      blood_type: '',
      // illness_history: '',
      father_name: '',
      mother_name: '',
      // father_birth_place: '',
      // mother_birth_place: '',
      // father_birth_date: '',
      // mother_birth_date: '',
      // father_religion: '',
      // mother_religion: '',
      // father_nationality: '',
      // mother_nationality: '',
      father_education_level_id: '',
      mother_education_level_id: '',
      // father_education_major: '',
      // mother_education_major: '',
      // father_salary: '',
      // mother_salary: '',
      // father_profession: '',
      // mother_profession: '',
      // father_rw: '',
      // mother_rw: '',
      // father_rt: '',
      // mother_rt: '',
      // father_address: '',
      // mother_address: '',
      // father_parental_status: '',
      // mother_parental_status: '',
      // father_handphone: '',
      // mother_handphone: '',
      // father_email: '',
      // mother_email: '',
      // father_nik: '',
      // mother_nik: '',
      // mutation: '',
      // school_origin: '',
      // school_address: '',
      // year_of_entry: '',
      // graduation_year: '',
      // skhun_no: '',
      // ijazah_no: '',
      // reason_to_move: '',
      // accepted_in_class: '',
      // effective_date_received: '',
      // fc_kk: null,
      // fc_akta: null,
      // fc_ijazah: null,
      // color_photo: null,
      // photo: null,
      // other_document: null,
    },
    locations: {
      province: '',
      city: '',
      district: '',
      subdistrict: '',
      // father_province: '',
      // mother_province: '',
      // father_city: '',
      // mother_city: '',
      // father_district: '',
      // mother_district: '',
      // father_subdistrict: '',
      // mother_subdistrict: '',
    },
    dataGender: [
      { value: 'M', text: 'Laki-laki' },
      { value: 'F', text: 'Perempuan' },
    ],
    dataReligion: [
      { value: 1, text: 'Islam' },
      { value: 2, text: 'Protestan' },
      { value: 3, text: 'Katolik' },
      { value: 4, text: 'Hindu' },
      { value: 5, text: 'Buddha' },
      { value: 6, text: 'Khonghucu' },
    ],
  }),
  computed: {
    filterCities() {
      const findCity = this.dataCities.filter(
        city => city.name === this.locations.city,
      )
      console.log(findCity[0])
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/students/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.name = data.name
      this.dataStudent.nisn = data.nisn
      this.dataStudent.birth_place = data.birth_place
      this.dataStudent.birth_date = data.birth_date.substr(0, 10)
      this.dataStudent.gender = data.gender
      this.dataStudent.religion = data.religion
      this.dataStudent.nationality = data.nationality
      this.dataStudent.child_number = data.child_number
      this.dataStudent.number_of_sibling = data.number_of_sibling
      this.dataStudent.relationship_in_family = data.relationship_in_family
      this.dataStudent.every_day_language = data.every_day_language
      this.dataStudent.address = data.address
      this.dataStudent.distance = data.distance
      this.dataStudent.child_number_institution = data.child_number_institution
      if (data.religion === 1) {
        this.dataStudent.reading_ability_quran = 'Lancar'
      }
      this.dataStudent.blood_type = data.blood_type
      // this.dataStudent.illness_history = data.illness_history
      this.dataStudent.father_education_level_id = 1
      this.dataStudent.mother_education_level_id = 1
      this.dataStudent.father_name = data.student_families.data[0].name
      this.dataStudent.mother_name = data.student_families.data[1].name
      this.locations.province = data.province
      this.locations.city = data.city
      this.locations.province = data.province
      this.locations.district = data.district
      this.locations.subdistrict = data.subdistrict
      // this.locations.father_province = data.student_families.data[0].province
      // this.locations.mother_province = data.student_families.data[1].province
      // this.locations.father_city = data.student_families.data[0].city
      // this.locations.mother_city = data.student_families.data[1].city
      // this.locations.father_district = data.student_families.data[0].district
      // this.locations.mother_district = data.student_families.data[1].district
      // this.locations.father_subdistrict = data.student_families.data[0].subdistrict
      // this.locations.mother_subdistrict = data.student_families.data[1].subdistrict
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            dataFormStudent.append('_method', 'PUT')
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            Object.keys(this.locations).forEach(key => {
              dataFormStudent.append(key, this.locations[key])
            })
            await this.$http.post(`/students/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Update Data Siswa Berhasil',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$router.push({ name: 'data-siswa' })
          } catch (err) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Update Data Siswa Gagal',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
            console.log(err)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
