var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Siswa ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name Lengkap","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Hendi Saputra"},model:{value:(_vm.dataStudent.name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "name", $$v)},expression:"dataStudent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NISN","label-for":"nisn"}},[_c('validation-provider',{attrs:{"name":"NISN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nisn","state":errors.length > 0 ? false : null,"placeholder":"9732398239XXX"},model:{value:(_vm.dataStudent.nisn),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nisn", $$v)},expression:"dataStudent.nisn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"birth_place"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_place","state":errors.length > 0 ? false : null,"placeholder":"Yogyakarta"},model:{value:(_vm.dataStudent.birth_place),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth_place", $$v)},expression:"dataStudent.birth_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"birth_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Lahir","type":"date"},model:{value:(_vm.dataStudent.birth_date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth_date", $$v)},expression:"dataStudent.birth_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"gender","reduce":function (gender) { return gender.value; },"options":_vm.dataGender,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":"Pilih Jenis Kelamin"},model:{value:(_vm.dataStudent.gender),callback:function ($$v) {_vm.$set(_vm.dataStudent, "gender", $$v)},expression:"dataStudent.gender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Agama","label-for":"religion"}},[_c('validation-provider',{attrs:{"name":"Agama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"religion","reduce":function (religion) { return religion.value; },"options":_vm.dataReligion,"label":"text","placeholder":"Pilih Agama"},model:{value:(_vm.dataStudent.religion),callback:function ($$v) {_vm.$set(_vm.dataStudent, "religion", $$v)},expression:"dataStudent.religion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kewarganegaraan","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"Kewarganegaraan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"nationality","reduce":function (nationality) { return nationality.name; },"options":_vm.dataCountries,"label":"name","placeholder":"Pilih Kewarganegaraan"},model:{value:(_vm.dataStudent.nationality),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nationality", $$v)},expression:"dataStudent.nationality"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alamat","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Jl. Kaliurang Km.07 B12"},model:{value:(_vm.dataStudent.address),callback:function ($$v) {_vm.$set(_vm.dataStudent, "address", $$v)},expression:"dataStudent.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Anak ke","label-for":"child_number"}},[_c('validation-provider',{attrs:{"name":"Anak ke","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_number","state":errors.length > 0 ? false : null,"placeholder":"2"},model:{value:(_vm.dataStudent.child_number),callback:function ($$v) {_vm.$set(_vm.dataStudent, "child_number", $$v)},expression:"dataStudent.child_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jumlah Saudara Kandung","label-for":"number_of_sibling"}},[_c('validation-provider',{attrs:{"name":"Jumlah Saudara Kandung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number_of_sibling","state":errors.length > 0 ? false : null,"placeholder":"3"},model:{value:(_vm.dataStudent.number_of_sibling),callback:function ($$v) {_vm.$set(_vm.dataStudent, "number_of_sibling", $$v)},expression:"dataStudent.number_of_sibling"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Golongan Darah","label-for":"blood_type"}},[_c('validation-provider',{attrs:{"name":"Golongan Darah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blood_type","state":errors.length > 0 ? false : null,"placeholder":"O"},model:{value:(_vm.dataStudent.blood_type),callback:function ($$v) {_vm.$set(_vm.dataStudent, "blood_type", $$v)},expression:"dataStudent.blood_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }